import * as fabric from 'fabric';
import { FabricCanvasAgentObject } from 'types/fabric-agent-map';

export const addNameObject = (
  object: FabricCanvasAgentObject | (fabric.Rect & { id: string | number }),
  canvas: fabric.Canvas,
  name?: string
) => {
  const { left, top, width, id } = object;

  const input = new fabric.Textbox(name ?? '', {
    left: left,
    top: top - 14,

    width: width,
    fontSize: 14,
    editable: true,
    textAlign: 'left',
    fill: 'black',
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    selectable: false
  });
  input.set({
    id
  });

  canvas.add(input);
  return input;
};
