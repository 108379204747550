import { Typography } from 'antd';
import { OptionCompactDto } from 'types';
import { USER_STATUS } from 'utils';

type userStatusProps = {
  value: OptionCompactDto;
};

const UserStatus = ({ value }: userStatusProps) => {
  const render = () => {
    switch (value.optionId) {
      case USER_STATUS.ACTIVE:
        return (
          <div className='flex items-center gap-2'>
            <Typography.Text className='text-colorBgSuccess'>•</Typography.Text>
            <Typography.Text className='text-sm'>{value.name}</Typography.Text>
          </div>
        );
      case USER_STATUS.INACTIVE:
        return (
          <div className='flex items-center gap-2'>
            <Typography.Text className='text-colorError'>•</Typography.Text>
            <Typography.Text className='text-sm'>{value.name}</Typography.Text>
          </div>
        );
    }
  };
  return <>{render()}</>;
};

export default UserStatus;
