import { Form, FormInstance, Input, Space, Spin, Switch, TimePicker, Typography } from 'antd';
import { Avatar, FormItem, message } from 'components/common';
import dayjs, { Dayjs } from 'dayjs';
import { messages, shiftsMessages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateShiftMutation, useGetShiftDetailQuery, useUpdateShiftMutation } from 'services';
import { CreateShiftDto } from 'types';
import {
  createShiftInitialValues,
  SHIFT_DEFAULT_ICONS,
  shiftsValidationRules,
  TIME_FORMAT,
  updateShiftInitialValues
} from 'utils';

export type ShiftFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  shiftId?: number;
};

export type ShiftFormRefProps = {
  form: FormInstance<ShiftFormType>;
  isLoading: boolean;
};

export type ShiftFormType = Omit<CreateShiftDto, 'fromTime' | 'toTime'> & {
  toTime: Dayjs;
  fromTime: Dayjs;
};
const ShiftForm = forwardRef<ShiftFormRefProps, ShiftFormProps>(
  ({ onChangeLoading, onCreateSuccess, shiftId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const { data: shift, isLoading: isLoadingDetail } = useGetShiftDetailQuery(shiftId!, {
      skip: !shiftId,
      refetchOnMountOrArgChange: true
    });

    useEffect(() => {
      if (shift && shiftId) {
        form.setFieldsValue({
          code: shift.data.code,
          name: shift.data.name,
          fromTime: dayjs(shift.data.fromTime, TIME_FORMAT),
          toTime: dayjs(shift.data.toTime, TIME_FORMAT),
          isActive: shift.data.isActive,
          icon: shift?.data.icon
        });
      }
    }, [shift, shiftId]);

    const [form] = Form.useForm<ShiftFormType>();
    const icon = Form.useWatch('icon', form);

    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateShiftMutation();
    const [onCreate, { isLoading: isLoadingCreate }] = useCreateShiftMutation();

    const onFinish = ({ ...values }: ShiftFormType) => {
      const data: CreateShiftDto = {
        ...values,
        fromTime: values.fromTime.format(TIME_FORMAT),
        toTime: values.toTime.format(TIME_FORMAT)
      };

      if (!shiftId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          shiftId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate || isLoadingCreate);
      }
    }, [onChangeLoading, isLoadingUpdate, isLoadingCreate]);

    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        requiredMark={false}
        form={form}
        name='shiftForm'
        onFinish={onFinish}
        layout='vertical'
        validateMessages={validateMessages}
        initialValues={shiftId ? updateShiftInitialValues : createShiftInitialValues}
      >
        <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
          <FormItem.FloatLabel<ShiftFormType>
            name='code'
            rules={shiftsValidationRules.code}
            label={shiftsMessages.code}
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ShiftFormType>
            name='name'
            rules={shiftsValidationRules.name}
            label={shiftsMessages.name}
          >
            <Input />
          </FormItem.FloatLabel>
          <div className='mb-4'>
            <div className='flex w-2/3 gap-4'>
              <FormItem.FloatLabel<ShiftFormType>
                className='mb-0 flex-1'
                name='fromTime'
                rules={shiftsValidationRules.fromTime}
                label={shiftsMessages.fromTime}
              >
                <TimePicker showNow={false} needConfirm={false} className='w-full' format={TIME_FORMAT} />
              </FormItem.FloatLabel>
              <FormItem.FloatLabel<ShiftFormType>
                className='mb-0 flex-1'
                name='toTime'
                rules={shiftsValidationRules.toTime}
                label={shiftsMessages.toTime}
              >
                <TimePicker showNow={false} needConfirm={false} className='w-full' format={TIME_FORMAT} />
              </FormItem.FloatLabel>
            </div>
          </div>
          <Space align='center' className='mb-4'>
            <Form.Item<ShiftFormType>
              noStyle
              name='isActive'
              valuePropName='checked'
              rules={shiftsValidationRules.isActive}
            >
              <Switch />
            </Form.Item>
            <Typography.Text>{messages.statusEnum.active}</Typography.Text>
          </Space>
          <FormItem<ShiftFormType> name='icon' label={shiftsMessages.icon} rules={shiftsValidationRules.icon}>
            <Avatar.Select
              fileId={icon}
              iconItems={SHIFT_DEFAULT_ICONS}
              onChange={(fileId) => {
                form.setFieldValue('icon', fileId);
              }}
              selectOnly
              avatarBackground='transparent'
            />
          </FormItem>
        </Spin>
      </Form>
    );
  }
);
export default ShiftForm;
