import { findIndex } from 'lodash';
import { FabricAgentMapOtherObject, FabricCanvasAgentObject } from 'types/fabric-agent-map';
import * as fabric from 'fabric';
import { findNameOfObject } from './findNameOfObject';
import { addNameObject } from './addNameObject';

export const modifiedObject = (
  object: FabricCanvasAgentObject,
  objects: FabricAgentMapOtherObject[],
  canvas?: fabric.Canvas | null
): FabricAgentMapOtherObject[] => {
  const newSeat = [...objects];

  if (object) {
    const idxTarget = findIndex(newSeat, { id: object.id });

    const left = object.left;
    const top = object.top;
    const width = Math.round(object.width * object.scaleX);
    const height = Math.round(object.height * object.scaleY);
    const angle = object.angle;
    object.set({
      width,
      height,
      scaleY: 1,
      scaleX: 1
    });

    if (idxTarget !== -1) {
      if (newSeat[idxTarget].name && canvas) {
        const name = findNameOfObject(object, canvas);
        if (!name) {
          addNameObject(object, canvas, newSeat[idxTarget].name);
        }
      }
      newSeat[idxTarget] = {
        ...newSeat[idxTarget],
        left,
        top,
        width,
        height,
        angle
      };
    }
  }
  return newSeat;
};
