import ServerUpload from './ServerUpload';
import OriginUpload from './Upload';
import UploadCrop from './UploadCrop';
import UploadFileItem from './UploadFileItem';

export type UploadProps = typeof OriginUpload & {
  ServerUpload: typeof ServerUpload;
  Crop: typeof UploadCrop;
};
const Upload = OriginUpload as UploadProps;
Upload.ServerUpload = ServerUpload;
Upload.Crop = UploadCrop;

export { Upload, UploadFileItem };
