import { Typography } from 'antd';
import { TreeChildrenIcon } from 'assets';
import { RightSideCheckbox } from 'components/common/checkbox';
import { useOrganizationUnitsHierarchy } from 'hooks';
import { FindOrganizationUnitHierarchyDto, OrganizationUnitHierarchyDto } from 'types';

type TreeCheckboxOrganizationUnitsProps = FindOrganizationUnitHierarchyDto & {
  value: number[];
  onChange: (value: number[]) => void;
};

const TreeCheckboxOrganizationUnits: React.FC<TreeCheckboxOrganizationUnitsProps> = ({
  parentId,
  organizationUnitId,
  value,
  onChange
}) => {
  const { organizationsUnitsHierarchy } = useOrganizationUnitsHierarchy({
    parentId,
    organizationUnitId
  });

  const formatData = (data: OrganizationUnitHierarchyDto[]): any[] => {
    if (data.length === 0) return [];

    return data.map((item) => ({
      key: item.organizationUnitId.toString(),
      value: item.organizationUnitId.toString(),
      label: item.name,
      children: item.children && item.children.length > 0 ? formatData(item.children) : []
    }));
  };

  const handleChange = (selectedValue: number) => {
    if (value.includes(selectedValue)) {
      onChange(value.filter((value) => value !== selectedValue));
    } else {
      onChange([...value, selectedValue]);
    }
  };

  return (
    <RightSideCheckbox
      options={formatData(organizationsUnitsHierarchy)}
      onClick={(value) => handleChange(Number(value))}
      prefix={<Typography.Text className='font-semibold'>•</Typography.Text>}
      childrenPrefix={<TreeChildrenIcon />}
      value={value.map((item) => item.toString())}
    />
  );
};
export default TreeCheckboxOrganizationUnits;
