import { useProfile } from 'hooks/auth';
import { findIndex, sumBy, uniqBy } from 'lodash';
import { ConversationDto, MessageDto } from 'types';
import { MessagePosition } from 'types/message-position.type';
import {
  calculateHeightMessageItem,
  CONVERSATION_TYPE,
  getPositionsMessageItems,
  MESSAGE_SPACING,
  MESSAGE_SPACING_END,
  MESSAGE_SPACING_LARGE
} from 'utils';
export function useReceiveInternalChatMessage(conversation: ConversationDto) {
  const { profile } = useProfile();

  const handleReceiveOlderMessageList = (
    messageList: MessageDto[],
    firstMessage?: MessageDto | null,
    positionsList?: MessagePosition[]
  ) => {
    /** Thêm tin nhắn hiện tại đầu tiên vào tin nhắn được thêm */
    let newMessages = [...messageList];
    if (firstMessage) newMessages.push(firstMessage);

    /** Tính chiều cao và danh sách vị trí của tin nhắn mới */
    const { height, positions } = getPositionsMessageItems(newMessages, conversation, profile?.userId);

    /** Remove vị trí tin nhắn hiện tại đầu tiên ra khỏi danh sách hiện tại */
    const firstMessagePositions = positionsList?.[0];
    const removedHeight = firstMessagePositions ? firstMessagePositions.height + firstMessagePositions.position : 0;

    /** Tính lại vị trí của các tin nhắn hiện tại */
    const positionsAfterRemovedFirst = positionsList
      ? positionsList.slice(1, positionsList.length).map((pos) => ({
          ...pos,
          /** Đẩy vị trí của các tin nhắn hiện tại  */
          position: pos.position + (height - removedHeight)
        }))
      : [];

    if (height) {
      /** Thêm đoạn tin nhắn vào đầu tin nhắn hiện tại */
      let newPositions = [...positions, ...positionsAfterRemovedFirst];

      /** Tính lại tổng chiều cao của message box */
      newPositions = uniqBy(newPositions, (o) => o.messageId);
      const totalHeight = sumBy(newPositions, (o) => o.height);
      return {
        height: totalHeight + MESSAGE_SPACING_END,
        positions: uniqBy(newPositions, (o) => o.messageId)
      };
    }
    return null;
  };

  const handleReceiveNewerMessageList = (
    messageList: MessageDto[],
    lastMessage?: MessageDto | null,
    positionsList?: MessagePosition[]
  ) => {
    let newPositions = positionsList ?? [];
    /** Thêm tin nhắn hiện tại đầu tiên vào tin nhắn được thêm */
    let newMessages = [...messageList];
    if (lastMessage) newMessages.unshift(lastMessage);

    /** Tin nhắn cuối nhận dc */
    const message = messageList[messageList.length - 1];

    /** Tính chiều cao và danh sách vị trí của tin nhắn mới */
    const { height, positions } = getPositionsMessageItems(newMessages, conversation, profile?.userId);
    if (height) {
      /** Giảm khoảng cách tin nhắn cuối hiện tại nếu 2 tin nhắn cùng một người gửi */
      /** Vị trí của tin nhắn cuối cùng */
      const lastIdx = findIndex(newPositions, {
        messageId: lastMessage?.messageId
      });

      if (lastMessage?.senderId === message?.senderId && newPositions[lastIdx]?.height) {
        newPositions[lastIdx].height -= MESSAGE_SPACING_LARGE - MESSAGE_SPACING;
      }
      const prevTotalHeight = sumBy(newPositions, (o) => o.height);
      const firstItem = positions[0];

      newPositions = [
        ...newPositions,
        ...positions.map((o) => ({
          ...o,
          position: o.position + prevTotalHeight - firstItem.height
        }))
      ];
      /** Tính lại tổng chiều cao của message box */
      newPositions = uniqBy(newPositions, (o) => o.messageId);
      const totalHeight = sumBy(newPositions, (o) => o.height);
      return {
        height: totalHeight + MESSAGE_SPACING_END,
        positions: uniqBy(newPositions, (o) => o.messageId)
      };
    }
    return null;
  };

  const handleReceiveNewMessage = (
    message: MessageDto,
    lastMessage?: MessageDto | null,
    positionsList?: MessagePosition[]
  ) => {
    let newPositions = positionsList ?? [];

    /** Vị trí của tin nhắn cuối cùng */
    const lastIdx = findIndex(newPositions, {
      messageId: lastMessage?.messageId
    });
    const lastItem = newPositions[lastIdx];

    /** Tính chiều cao của tin nhắn mới nhất */
    const heightMessage = calculateHeightMessageItem(
      message,
      undefined,
      lastMessage,
      conversation?.type === CONVERSATION_TYPE.GROUP,
      profile?.userId,
      conversation?.participants.find((participant) => participant.userId === message.senderId)
    );
    if (heightMessage > 0) {
      /** Giảm khoảng cách tin nhắn cuối hiện tại nếu 2 tin nhắn cùng một người gửi */
      if (lastMessage?.senderId === message?.senderId && newPositions[lastIdx]?.height) {
        newPositions[lastIdx].height -= MESSAGE_SPACING_LARGE - MESSAGE_SPACING;
      }
      /** Thêm postion của tin nhắn mới vào list */
      newPositions.push({
        messageId: message.messageId,
        height: heightMessage,
        position: lastItem ? lastItem.height + lastItem.position : heightMessage
      });

      /** Tính lại tổng chiều cao của message box */
      newPositions = uniqBy(newPositions, (o) => o.messageId);
      const totalHeight = sumBy(newPositions, (o) => o.height);
      return {
        height: totalHeight + MESSAGE_SPACING_END,
        positions: uniqBy(newPositions, (o) => o.messageId)
      };
    }
    return null;
  };
  return {
    handleReceiveOlderMessageList,
    handleReceiveNewerMessageList,
    handleReceiveNewMessage
  };
}
