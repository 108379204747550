import { RcFile } from 'antd/es/upload';
import * as XLSX from 'xlsx-js-style';

const style: XLSX.CellStyle = {
  font: {
    name: 'Times New Roman',
    sz: 16
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true
  }
};

export function createExcelBuffer(rows: any[][]): Buffer {
  const worksheet = XLSX.utils.aoa_to_sheet(rows, { cellStyles: true });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet);

  const numOfCols = Math.floor(Object.keys(worksheet).filter((key) => key[0] !== '!').length / 2);
  worksheet['!cols'] = Array(numOfCols).fill({ width: 30 });

  for (let cell in worksheet) {
    if (cell[0] === '!') {
      continue;
    }

    worksheet[cell].s = style;
  }

  const buffer = XLSX.write(workbook, { type: 'buffer', cellStyles: true });
  return buffer;
}

export const readFileExcel = (file: RcFile): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const jsonData = XLSX.utils
            .sheet_to_json(worksheet, { header: 1 })
            .filter((row: any) => row.some((cell: any) => cell !== undefined && cell !== null && cell !== ''));

          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);

      reader.readAsArrayBuffer(file as RcFile);
    } catch (error) {
      reject(error);
    }
  });
};
