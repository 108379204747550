// Define the enum using the keys from SidebarMenuItems
export enum SIDEBAR_MENU_KEY {
  CUSTOMER_LOOKUP = 'customerLookup',
  REQUEST_FORM = 'requestForm',
  CALL_CENTER_MANAGEMENT = 'callCenterManagement',
  REPORTS = 'reports',
  MANAGEMENT = 'management',
  INTERNAL_ANNOUNCEMENTS = 'internalAnnouncements',
  VIP_CUSTOMERS = 'vipCustomers',
  AGENT_MAPS = 'agentMaps',
  AGENT_STATUS = 'agentStatus',
  WORKING_SCHEDULES = 'workingSchedules',
  SERVICE_INSTRUCTIONS = 'serviceInstructions',
  CUSTOMER_SUPPORT_INFORMATION = 'customerSupportInformation',
  FREQUENTLY_ASKED_QUESTIONS = 'frequentlyAskedQuestions',
  FANPAGE_CONNECTION = 'fanpageConnection',
  TICKET_CANCEL_REASONS = 'ticketCancelReasons',
  BUSY_REASONS = 'busyReasons',
  SYSTEM = 'system',
  USERS = 'users',
  USER_GROUPS = 'userGroups',
  PERMISSIONS = 'permissions',
  ORGANIZATION_UNIT = 'organizationUnit',
  SERVICE_TYPES = 'serviceTypes',
  TICKET_SAMPLES = 'ticketSamples',
  PROFILE = 'profile',
  SHIFTS = 'shifts',
  SYSTEM_CONFIGS = 'systemConfigs'
}
