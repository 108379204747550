import { Modal as ModalAntd } from 'antd';
import SmallModal from './SmallModal ';
import StickerModal from './StickerModal';

export type ModalProps = typeof ModalAntd & {
  Sticker: typeof StickerModal;
  Small: typeof SmallModal;
};
const Modal = ModalAntd as ModalProps;

Modal.Small = SmallModal;
Modal.Sticker = StickerModal;

export { Modal };
