import { FileOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { find } from 'lodash';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { colorsBase } from 'themes';
import { convertFileType, FILE_SYMBOL } from 'utils';

type OtherFileProps = {
  type?: string;
  name?: string;
  className?: string;
  showInfo?: boolean;
};
const OtherFile: React.FC<OtherFileProps> = ({ type, name, className, showInfo = true }) => {
  const fileSymbol = find(FILE_SYMBOL, {
    value: convertFileType(type)
  });
  return (
    <div className={twMerge('flex h-full w-full items-center gap-2', className)}>
      <div
        className='flex h-8 w-8 items-center justify-center rounded text-white'
        style={{
          background: fileSymbol?.color || colorsBase.colorPrimary
        }}
      >
        {fileSymbol?.icon || <FileOutlined className='text-white' />}
      </div>
      {showInfo ? (
        <div className='flex max-w-36 flex-col text-left'>
          <Typography.Text className='text-sm font-semibold' ellipsis>
            {name ?? '-'}
          </Typography.Text>
          <Typography.Text className='text-xs' ellipsis>
            {type ?? '-'}
          </Typography.Text>
        </div>
      ) : null}
    </div>
  );
};
export default OtherFile;
