import { MenuProps, Typography } from 'antd';
import { DownloadFileIcon } from 'assets';
import { File, MessageOptions } from 'components/common';
import dayjs from 'dayjs';
import { internalChatMessages } from 'messages';
import { memo, useRef, useState } from 'react';
import { FileDto, MessageDto } from 'types';
import { fileServerDownload, MESSAGE_FILE_HEIGHT, TIME_FORMAT } from 'utils';
import { MessageOptionsRef } from './MessageOptions';

type MessageFileProps = {
  fileId: number;
  sentAt?: string | Date;
  onForwardMessage?: (message: MessageDto) => void;
  data: MessageDto;
};

const MessageFile: React.FC<MessageFileProps> = ({ onForwardMessage, data, fileId, sentAt }) => {
  const [file, setFile] = useState<FileDto>();
  const optionsRef = useRef<MessageOptionsRef>(null);

  const items: MenuProps['items'] = [
    {
      key: 'download',
      label: internalChatMessages.download,
      onClick: () => {
        if (file) {
          fileServerDownload(file);
        }
      },
      icon: <DownloadFileIcon />
    }
  ];
  return (
    <div className='relative'>
      <div
        onContextMenu={(e) => {
          optionsRef.current?.open(e);
        }}
        style={{
          height: MESSAGE_FILE_HEIGHT
        }}
        className='relative w-[260px] cursor-pointer rounded-lg border px-3'
      >
        <File.Server
          onDataFetched={(file) => {
            setFile(file);
          }}
          fileId={Number(fileId)}
          className='h-full w-full border-0 p-0'
        />
        <div className='absolute bottom-1 right-1 rounded-2xl bg-textHoverBg px-1'>
          <Typography.Paragraph className='mb-0 text-xs' type='secondary'>
            {dayjs(sentAt).format(TIME_FORMAT)}
          </Typography.Paragraph>
        </div>
      </div>
      <MessageOptions onForwardMessage={onForwardMessage} ref={optionsRef} items={items} message={data} />
    </div>
  );
};

export default memo(MessageFile);
