import { Upload as UploadAntd } from 'antd';
import { UploadFile, UploadProps as UploadPropsAntd } from 'antd/es/upload';
import { FileDto } from 'types';
import { ServerFileWithUid } from './ServerUpload';
import UploadFileItem from './UploadFileItem';

export type UploadProps = UploadPropsAntd & {
  onUploadSuccess?: (value: FileDto) => void;
  itemClassName?: string;
};

const Upload = ({ onUploadSuccess, itemClassName, ...props }: UploadProps) => {
  const fileList = props.fileList
    ? Array.isArray(props.fileList)
      ? (props.fileList as UploadFile[])
      : [props.fileList]
    : undefined;

  return (
    <UploadAntd
      itemRender={(
        _origin: React.ReactElement,
        file: UploadFile | ServerFileWithUid,
        _fileList: Array<UploadFile | number>,
        actions
      ) => {
        return <UploadFileItem file={file} remove={() => actions.remove()} />;
      }}
      prefixCls='custom-upload'
      customRequest={() => true}
      fileList={fileList || undefined}
      {...props}
    />
  );
};

export default Upload;
