export const permissionsMessages = {
  page: 'Phân quyền',
  name: 'Tên quyền',
  description: 'Mô tả',
  user: 'Người dùng',
  group: 'Nhóm',
  title: 'Quyền',
  scope: 'Phạm vi áp dụng',
  organizationUnit: 'Đơn vị / phòng đội được áp dụng',
  updateScope: 'Cập nhật phạm vi',
  allOrganizationUnits: 'Tất cả đơn vị'
};
