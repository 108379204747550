import * as fabric from 'fabric';
import { colorsBase } from 'themes';
import { FabricAgentMapSeat } from 'types/fabric-agent-map';
import { generateUUID } from 'utils/common';

export const addSeat = (canvas: fabric.Canvas, seat?: Partial<FabricAgentMapSeat>): FabricAgentMapSeat => {
  const generateId = generateUUID();
  const fill = colorsBase.backgroundColor2;

  const rect = new fabric.Rect({
    left: 0,
    top: 0,
    fill: fill,
    width: seat?.width ?? 88,
    height: seat?.height ?? 88,
    stroke: colorsBase.colorBorder,
    rx: 12,
    ry: 12
  });

  const input = new fabric.Textbox(seat?.seatIp || '-', {
    left: 8,
    top: 50,
    width: 72,
    fontSize: 14,
    editable: true,
    textAlign: 'center',
    splitByGrapheme: true,
    fill: 'black'
  });

  const group = new fabric.Group([rect, input], {
    left: seat?.left ?? 0,
    top: seat?.top ?? 0,
    selectable: true,
    lockRotation: true,
    hasControls: false
  });

  group.set({ id: seat?.id ?? generateId });
  canvas.add(group);
  if (!seat) {
    canvas.setActiveObject(group);
  }

  return {
    left: 0,
    top: 0,
    width: 88,
    height: 88,
    id: generateId,
    seatIp: '',
    ...(seat || {})
  };
};
