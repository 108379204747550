import { Form, FormInstance, Input, InputNumber, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Editor, FormItem, message } from 'components';
import SelectServiceTypes from 'components/service-types/SelectServiceTypes';
import { validateMessages } from 'messages';
import { frequentlyAskedQuestionsMessages } from 'messages/frequently-asked-questions.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateFrequentlyAskedQuestionMutation,
  useGetFrequentlyAskedQuestionDetailQuery,
  useUpdateFrequentlyAskedQuestionMutation
} from 'services';
import { CreateFrequentlyAskedQuestionDto } from 'types';
import { SERVICE_TYPE_SELECT_ALL } from 'utils';
import {
  createFrequentlyAskedQuestionInitialValues,
  updateFrequentlyAskedQuestionsInitialValues
} from 'utils/initial-values/frequently-asked-questions';
import { frequentlyAskedQuestionsValidationRules } from 'utils/validation-rules';
export type FrequentlyAskedQuestionFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onSubmitSuccess?: () => void;
  frequentlyAskedQuestionId?: number;
};

export type FrequentlyAskedQuestionFormRefProps = {
  form: FormInstance<FrequentlyAskedQuestionFormType>;
  isLoading: boolean;
};

export type FrequentlyAskedQuestionFormType = Omit<CreateFrequentlyAskedQuestionDto, 'serviceTypeId'> & {
  serviceTypeId: DefaultOptionType;
};
const FrequentlyAskedQuestionForm = forwardRef<FrequentlyAskedQuestionFormRefProps, FrequentlyAskedQuestionFormProps>(
  ({ onChangeLoading, onSubmitSuccess, frequentlyAskedQuestionId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const { data: frequentlyAskedQuestion, isLoading: isLoadingDetail } = useGetFrequentlyAskedQuestionDetailQuery(
      frequentlyAskedQuestionId!,
      {
        skip: !frequentlyAskedQuestionId,
        refetchOnMountOrArgChange: true
      }
    );

    useEffect(() => {
      if (frequentlyAskedQuestion && frequentlyAskedQuestionId) {
        form.setFieldsValue({
          ...frequentlyAskedQuestion.data,
          serviceTypeId: frequentlyAskedQuestion.data.serviceTypeId
            ? {
                label: frequentlyAskedQuestion.data.serviceType?.name,
                value: frequentlyAskedQuestion.data.serviceTypeId
              }
            : SERVICE_TYPE_SELECT_ALL
        });
      }
    }, [frequentlyAskedQuestion, frequentlyAskedQuestionId]);

    const [onCreate, { isLoading: isLoadingCreate }] = useCreateFrequentlyAskedQuestionMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateFrequentlyAskedQuestionMutation();

    const [form] = Form.useForm<FrequentlyAskedQuestionFormType>();
    const content = Form.useWatch('content', form);

    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    const onFinish = ({ ...values }: FrequentlyAskedQuestionFormType) => {
      const data: CreateFrequentlyAskedQuestionDto = {
        ...values,
        serviceTypeId: values.serviceTypeId.value as number
      };
      if (!frequentlyAskedQuestionId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onSubmitSuccess?.();
          });
      } else {
        onUpdate({
          frequentlyAskedQuestionId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onSubmitSuccess?.();
          });
      }
    };

    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='userForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={
          frequentlyAskedQuestionId
            ? updateFrequentlyAskedQuestionsInitialValues
            : createFrequentlyAskedQuestionInitialValues
        }
      >
        <Spin spinning={isLoadingCreate || isLoadingUpdate || isLoadingDetail}>
          <FormItem.FloatLabel<FrequentlyAskedQuestionFormType>
            name='title'
            label={frequentlyAskedQuestionsMessages.title}
            rules={frequentlyAskedQuestionsValidationRules.title}
          >
            <Input />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<FrequentlyAskedQuestionFormType>
            name='keyword'
            label={frequentlyAskedQuestionsMessages.keyword}
            rules={frequentlyAskedQuestionsValidationRules.keyword}
          >
            <Input />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<FrequentlyAskedQuestionFormType>
            name='serviceTypeId'
            label={frequentlyAskedQuestionsMessages.serviceType}
            rules={frequentlyAskedQuestionsValidationRules.serviceTypeId}
            classFloatLabel='has-value'
          >
            <SelectServiceTypes labelInValue defaultActiveFirstOption isAll />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<FrequentlyAskedQuestionFormType>
            name='priority'
            label={frequentlyAskedQuestionsMessages.priority}
            rules={frequentlyAskedQuestionsValidationRules.priority}
          >
            <InputNumber min={1} className='w-1/4' />
          </FormItem.FloatLabel>

          <>
            <Editor
              value={content}
              onEditorChange={(a) => {
                form.setFieldValue('content', a);
              }}
            />
            <FormItem<FrequentlyAskedQuestionFormType>
              name='content'
              label={frequentlyAskedQuestionsMessages.content}
              rules={frequentlyAskedQuestionsValidationRules.content}
              className='hide-label'
            ></FormItem>
          </>
        </Spin>
      </Form>
    );
  }
);
export default FrequentlyAskedQuestionForm;
