import {createApi} from '@reduxjs/toolkit/query/react';
import { result } from 'lodash';




import { ResponseDto, ResponsePagingDto } from 'types';
import { CustomerDto } from 'types/dto/customer-lookup';
import { FindAllCustomersDto } from 'types/dto/customer-lookup/find-all-customers.dto';

import axiosBaseQuery from 'utils/base-api';
export const customerLookupApi = createApi({
    reducerPath: 'customerLookupApi',
    tagTypes: ['customers', 'customer_detail',],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        getCustomers: builder.query<ResponsePagingDto<CustomerDto>, FindAllCustomersDto>({
          query: ({ customerName, customerAddress, meterSerialNumber, customerCode, routeCode, meterPointCode}) => {
            const params = new URLSearchParams();
            if (customerName) params.append('customerName', customerName);
            if (customerAddress) params.append('customerAddress', customerAddress);
            if (meterSerialNumber) params.append('meterSerialNumber', meterSerialNumber);
            if (customerCode) params.append('customerCode', customerCode);
            if (routeCode) params.append('routeCode', routeCode);
            if (meterPointCode) params.append('meterPointCode', meterPointCode);
            return {
              url: `/customers?${params}&pageSize=10`,
              method: 'get',
            };
          },
          providesTags: (result) =>
            result && result.data.rows.length > 0
              ? result.data.rows.map(({customerId}) => ({
                  type: 'customers',
                  id: customerId
              }))
              : ['customers']
        }),
        getCustomersByFullText: builder.query<ResponsePagingDto<CustomerDto>, FindAllCustomersDto>({
          query: (params) => ({
            url: `/customers/fulltext_search?keyword=${params}`,
            method: 'get',
            params
          }),
          providesTags: (result) =>
            result && result.data.rows.length > 0
              ? result.data.rows.map(({ customerId }) => ({
                  type: 'customers',
                  id: customerId,
                }))
              : ['customers']
        }),
        getCustomerDetail: builder.query<ResponseDto<CustomerDto>, number>({
          query: (customerId) => ({ url: `/customers/${customerId}`, method: 'get'}),
          providesTags: (result) => (result ? [{ type: 'customer_detail', id: result.data.customerId}] : [])
        })
    })
})

export const {
    useGetCustomersQuery,
    useGetCustomerDetailQuery,
} = customerLookupApi