import { Radio, Typography } from 'antd';
import { message } from 'components/common';
import { useOptions } from 'hooks';
import { internalAnnouncementsMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useGetInternalAnnouncementDetailQuery, useUpdateInternalAnnouncementMutation } from 'services';
import { CORE_OPTION_TYPES, INTERNAL_ANNOUNCEMENT_STATUS, MICROSERVICES } from 'utils';

export type InternalAnnouncementStatusProps = {
  internalAnnouncementId: number;
  onChangeLoading?: (value: boolean) => void;
  onSuccess?: () => void;
};

export type InternalAnnouncementStatusRefProps = {
  handleUpdate: () => void;
};

const InternalAnnouncementStatus = forwardRef<InternalAnnouncementStatusRefProps, InternalAnnouncementStatusProps>(
  ({ internalAnnouncementId, onChangeLoading, onSuccess }, ref) => {
    useImperativeHandle(ref, () => ({
      handleUpdate
    }));

    const { data: internalAnnouncement } = useGetInternalAnnouncementDetailQuery(internalAnnouncementId!, {
      skip: !internalAnnouncementId
    });

    const { data: optionsStatus } = useOptions({
      optionTypeId: CORE_OPTION_TYPES.INTERNAL_ANNOUNCEMENT_STATUS,
      service: MICROSERVICES.CORE
    });
    const data = internalAnnouncement?.data;
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateInternalAnnouncementMutation();

    const [status, setStatus] = useState<INTERNAL_ANNOUNCEMENT_STATUS | undefined>(data?.status.optionId); // Trạng thái ban đầu là "Chờ duyệt"

    const onChange = (e: any) => {
      setStatus(e.target.value);
    };

    const getAvailableStatuses = () => {
      switch (data?.status.optionId) {
        case INTERNAL_ANNOUNCEMENT_STATUS.WAITING_APPROVE:
          return optionsStatus.filter(
            (o) =>
              o.optionId === INTERNAL_ANNOUNCEMENT_STATUS.ACTIVE || o.optionId === INTERNAL_ANNOUNCEMENT_STATUS.INACTIVE
          );
        case INTERNAL_ANNOUNCEMENT_STATUS.ACTIVE:
          return optionsStatus.filter((o) => o.optionId === INTERNAL_ANNOUNCEMENT_STATUS.INACTIVE);
        case INTERNAL_ANNOUNCEMENT_STATUS.INACTIVE:
          return optionsStatus.filter((o) => o.optionId === INTERNAL_ANNOUNCEMENT_STATUS.ACTIVE);
        default:
          return [];
      }
    };
    const handleUpdate = () => {
      if (status) {
        onUpdate({
          internalAnnouncementId,
          statusId: status
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onSuccess?.();
          });
      }
    };

    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingUpdate]);

    return (
      <div>
        <Typography.Title level={5} className='mb-6'>
          {internalAnnouncementsMessages.currentStatus}: {data?.status.name}
        </Typography.Title>
        <Radio.Group className='flex flex-col gap-4' onChange={onChange} value={status}>
          {getAvailableStatuses().map((o) => (
            <Radio value={o.optionId}>{o.name}</Radio>
          ))}
        </Radio.Group>
      </div>
    );
  }
);
export default InternalAnnouncementStatus;
