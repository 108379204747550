export const organizationUnitsMessages = {
  shortTitle: 'Đơn vị',
  name: 'Đơn vị/ phòng đội',
  code: 'Mã quản lý',
  organizationUnitName: 'Tên đơn vị',
  organizationUnitQuantity: 'Số lượng',
  createNew: 'Tạo mới phòng đội',
  hasParent: 'Thuộc đơn vị/phòng đội cấp cao hơn',
  upperOrganizationUnit: 'Đơn vị cấp cao hơn',
  description: 'Mô tả',
  shortName: 'Tên thu gọn',
  service: 'Dịch vụ',
  update: 'Cập nhật phòng đội',
  confirmDelete: 'Bạn có chắc chắn muốn xóa đơn vị này không?',
  delete: 'Xóa đơn vị',
  classify: 'Phân loại',
  headUser: 'Trường phòng',
  deputyUser: 'Phó phòng'
};
