export const agentMapsMessages = {
  addSeat: 'Thêm chỗ ngồi',
  deleteSeat: 'Xóa chỗ ngồi',
  deleteObject: 'Xóa đối tượng',
  addWall: 'Thêm tường',
  addDoor: 'Thêm cửa',
  save: 'Lưu sơ đồ',
  name: 'Tên sơ đồ',
  errorSeats: 'Vui lòng nhập IP cho các chỗ ngồi',
  requiredSeats: 'Vui lòng thêm chỗ ngồi',
  drawGrid: 'Đang tải bản đồ'
};
