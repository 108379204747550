import { Divider, Space, Spin, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'components/common';
import dayjs from 'dayjs';
import { useInternalAnnouncementsStatisticPaging } from 'hooks';
import { internalAnnouncementsMessages, usersMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { InternalAnnouncementDto, InternalAnnouncementStatisticDto } from 'types';
import { DATE_FORMAT } from 'utils';

export type InternalAnnouncementStatisticProps = {
  isLoading?: boolean;
  data?: InternalAnnouncementDto;
};

export type InternalAnnouncementStatisticRefProps = {};

const InternalAnnouncementStatistic = forwardRef<
  InternalAnnouncementStatisticRefProps,
  InternalAnnouncementStatisticProps
>(({ data, isLoading }, ref) => {
  useImperativeHandle(ref, () => ({}));

  const {
    data: statisticData,
    count,
    pageIndex,
    handleChangePage,
    isLoading: statisticLoading,
    resetPage
  } = useInternalAnnouncementsStatisticPaging({
    internalAnnouncementId: data?.internalAnnouncementId!
  });
  useEffect(() => {
    resetPage();
  }, [data?.internalAnnouncementId]);
  const columns: ColumnsType<InternalAnnouncementStatisticDto> = [
    {
      title: usersMessages.employeeId,
      key: 'employeeId',
      render: (_, record) => record.user?.employeeId ?? '-'
    },
    {
      title: usersMessages.fullName,
      key: 'fullName',
      render: (_, record) => record.user?.fullName ?? '-'
    },
    {
      title: usersMessages.organize,
      key: 'organize',
      render: (_, record) => record.organizationUnit?.name ?? '-'
    },
    {
      title: internalAnnouncementsMessages.viewedAt,
      key: 'viewedAt',
      render: (_, record) => (record.viewedAt ? dayjs(record.viewedAt).format(DATE_FORMAT) : '-')
    },
    {
      title: internalAnnouncementsMessages.confirmedAt,
      key: 'confirmedAt',
      render: (_, record) => (record.confirmedAt ? dayjs(record.confirmedAt).format(DATE_FORMAT) : '-')
    }
  ];

  return (
    <div>
      <Spin spinning={isLoading}>
        <Space direction='vertical'>
          <Typography.Text>
            {internalAnnouncementsMessages.viewedCount}: {data?.viewedCount}
          </Typography.Text>
          {data?.isRequestConfirm && (
            <Typography.Text>
              {internalAnnouncementsMessages.confirmedCount}: {data?.confirmedCount}
            </Typography.Text>
          )}
        </Space>
        <Divider />
        <Table
          loading={statisticLoading}
          currentPage={pageIndex}
          count={count}
          handleChangePage={handleChangePage}
          columns={columns}
          dataSource={statisticData || []}
          rowKey={(record: InternalAnnouncementStatisticDto) => record.internalAnnouncementStatisticId}
        />
      </Spin>
    </div>
  );
});
export default InternalAnnouncementStatistic;
