import { message } from 'components';
import * as fabric from 'fabric';
import { find, findIndex } from 'lodash';
import { colorsBase } from 'themes';
import { FabricCanvasAgentObject, FabricAgentMapSeat } from 'types/fabric-agent-map';

export const onDoubleClickSeat = (
  canvasSeat: FabricCanvasAgentObject,
  seats: FabricAgentMapSeat[],
  canvas: fabric.Canvas,
  onChangeSeatIp: (data: FabricAgentMapSeat[]) => void
) => {
  const objectsSeat = canvasSeat.getObjects();
  const input = objectsSeat.find((o) => o.type === 'textbox') as fabric.Textbox;
  const box = objectsSeat.find((o) => o.type === 'rect') as fabric.Rect;

  if (input && canvas) {
    const { text, width } = input;

    const { left: leftGroup, top: topGroup } = canvasSeat;
    const leftNew = leftGroup + 8;
    const topNew = topGroup + 50;

    const newInput = new fabric.Textbox(text, {
      left: leftNew,
      top: topNew,
      width,
      fontSize: 14,
      editable: true,
      textAlign: 'center',
      splitByGrapheme: true, // Cho phép phân tách theo grapheme
      fill: colorsBase.colorTextBase,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false
    });
    canvas?.add(newInput);
    canvasSeat.remove(input);

    newInput.selectAll();
    newInput.enterEditing();
    canvas?.setActiveObject(newInput);
    canvas?.bringObjectForward(newInput);
    newInput.on('editing:exited', () => {
      const newSeats = [...seats];
      const idxTarget = findIndex(newSeats, { id: canvasSeat.id });
      if (idxTarget !== -1) {
        const checkIp = find(
          newSeats.filter((o) => o.id !== canvasSeat.id),
          { seatIp: newInput.text }
        );

        if (checkIp) {
          message.systemError('Ip trùng với chỗ ngồi khác');
          box.set({
            stroke: colorsBase.colorError,
            strokeWidth: 2
          });
          newSeats[idxTarget] = {
            ...newSeats[idxTarget],
            seatIp: newInput.text,
            error: true
          };
        } else {
          box.set({
            stroke: colorsBase.colorBorder,
            strokeWidth: 1
          });
          newSeats[idxTarget] = {
            ...newSeats[idxTarget],
            seatIp: newInput.text,
            error: false
          };
        }

        onChangeSeatIp(newSeats);
      }

      canvas?.remove(newInput);
      canvasSeat.add(newInput);
      canvas.renderAll();
    });

    canvas.renderAll();
  }
};
