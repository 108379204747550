import { Button, Divider, Modal, Space, Spin, Tabs, TabsProps, Typography } from 'antd';
import { message } from 'components/common';
import { internalAnnouncementsMessages, messages } from 'messages';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useDeleteInternalAnnouncementMutation, useGetInternalAnnouncementDetailQuery } from 'services';
import InternalAnnouncementForm, { InternalAnnouncementFormRefProps } from './InternalAnnouncementForm';
import InternalAnnouncementInfo from './InternalAnnouncementInfo';
import InternalAnnouncementStatistic from './InternalAnnouncementStatistic';
import InternalAnnouncementStatus, { InternalAnnouncementStatusRefProps } from './InternalAnnouncementStatus';

export type InternalAnnouncementDetailProps = {
  internalAnnouncementId: number;
  onDeleteSuccess?: () => void;
};

export type InternalAnnouncementDetailRefProps = {};
const { confirm } = Modal;

const InternalAnnouncementDetail = forwardRef<InternalAnnouncementDetailRefProps, InternalAnnouncementDetailProps>(
  ({ internalAnnouncementId, onDeleteSuccess }, ref) => {
    useImperativeHandle(ref, () => ({}));

    const [openStatus, setOpenStatus] = useState(false);
    const [loadingStatusRef, setLoadingStatus] = useState(false);
    const statusRef = useRef<InternalAnnouncementStatusRefProps>(null);

    const [open, setOpen] = useState(false);
    const [loadingFormRef, setLoadingForm] = useState(false);
    const formRef = useRef<InternalAnnouncementFormRefProps>(null);
    const handleUpdate = () => {
      confirm({
        title: `${internalAnnouncementsMessages.update} `,
        content: internalAnnouncementsMessages.confirmUpdate(data?.confirmedCount),
        cancelText: messages.cancelButtonText,
        okText: messages.confirmButtonText,
        onOk: () => {
          if (formRef.current) {
            formRef.current.form.submit();
          }
        },
        okButtonProps: {
          loading: loadingFormRef
        }
      });
    };

    const [onDelete, { isLoading: isLoadingDelete }] = useDeleteInternalAnnouncementMutation();

    const handleDelete = () => {
      confirm({
        title: `${internalAnnouncementsMessages.delete} `,
        content: internalAnnouncementsMessages.confirmDelete,
        cancelText: messages.cancelButtonText,
        okText: messages.confirmButtonText,
        onOk: () => {
          onDelete(internalAnnouncementId)
            .unwrap()
            .then((rs) => {
              message.systemSuccess(rs.message);
              onDeleteSuccess?.();
            })
            .catch();
        },
        okButtonProps: {
          loading: isLoadingDelete
        }
      });
    };

    const { data: internalAnnouncement, isLoading: isLoadingDetail } = useGetInternalAnnouncementDetailQuery(
      internalAnnouncementId!,
      {
        skip: !internalAnnouncementId,
        refetchOnMountOrArgChange: true
      }
    );
    const data = internalAnnouncement?.data;

    const items: TabsProps['items'] = [
      {
        key: '1',
        label: internalAnnouncementsMessages.info,
        children: <InternalAnnouncementInfo data={data} isLoading={isLoadingDetail} />
      },
      {
        key: '2',
        label: internalAnnouncementsMessages.statistic,
        children: <InternalAnnouncementStatistic data={data} isLoading={isLoadingDetail} />
      }
    ];
    return (
      <div className='rounded-lg border border-colorBorderInput bg-colorBgContent p-5'>
        <Spin spinning={isLoadingDetail}>
          {data && (
            <>
              <div>
                <Typography.Title level={3}>{data.title}</Typography.Title>
              </div>
              <Divider />
              <Space className='mb-6' direction='vertical'>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className='text-sm'
                >
                  {messages.editButtonText}
                </Button>
                <Button
                  onClick={() => {
                    setOpenStatus(true);
                  }}
                  className='text-sm'
                >
                  {internalAnnouncementsMessages.updateStatus}
                </Button>

                <Button onClick={handleDelete} className='text-sm'>
                  {messages.deleteButtonText}
                </Button>
              </Space>
              <Tabs defaultActiveKey='1' items={items} destroyInactiveTabPane />
            </>
          )}
        </Spin>
        <Modal
          footer={[
            <Button
              loading={loadingStatusRef}
              onClick={() => {
                if (statusRef.current) {
                  statusRef.current.handleUpdate();
                }
              }}
              size='large'
              type='primary'
            >
              {messages.saveButtonText}
            </Button>
          ]}
          open={openStatus}
          onCancel={() => {
            setOpenStatus(false);
          }}
          title={internalAnnouncementsMessages.updateStatus}
          destroyOnClose
        >
          <InternalAnnouncementStatus
            onChangeLoading={setLoadingStatus}
            ref={statusRef}
            internalAnnouncementId={internalAnnouncementId}
            onSuccess={() => {
              setOpenStatus(false);
            }}
          />
        </Modal>

        <Modal
          maskClosable={false}
          centered
          width={888}
          title={internalAnnouncementId ? internalAnnouncementsMessages.update : internalAnnouncementsMessages.create}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <Button
              loading={loadingFormRef}
              onClick={() => {
                if (data?.confirmedCount && data?.confirmedCount > 0) {
                  handleUpdate();
                } else {
                  if (formRef.current) {
                    formRef.current.form.submit();
                  }
                }
              }}
              size='large'
              type='primary'
            >
              {messages.saveButtonText}
            </Button>
          ]}
          destroyOnClose
        >
          <InternalAnnouncementForm
            refetchOnMountOrArgChange={false}
            onCreateSuccess={() => {
              setOpen(false);
            }}
            ref={formRef}
            onChangeLoading={setLoadingForm}
            internalAnnouncementId={internalAnnouncementId}
          />
        </Modal>
      </div>
    );
  }
);
export default InternalAnnouncementDetail;
