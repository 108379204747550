import { Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import type { UploadRequestOption as RcCustomRequestOptions, UploadRequestError } from 'rc-upload/lib/interface';
import { isValidJson, readFileExcel } from 'utils';
import Excel, { ExcelProps } from './Excel';

type UploadExcelProps = UploadProps & {
  excelProps?: Omit<ExcelProps, 'data'>;
};

export type UploadExcelDataType = {
  excelData: (string | number)[][];
};

const UploadExcel = ({ excelProps, ...props }: UploadExcelProps) => {
  const fileList = props.fileList ? (Array.isArray(props.fileList) ? props.fileList : [props.fileList]) : undefined;

  const handleUpload = async (options: RcCustomRequestOptions) => {
    const { onError, onSuccess, file } = options;
    readFileExcel(file as RcFile)
      .then((data) => {
        onSuccess?.({
          excelData: data
        });
      })
      .catch((error) => {
        onError?.(error as UploadRequestError);
      });
  };

  return (
    <Upload
      {...props}
      fileList={fileList}
      itemRender={(_origin: React.ReactElement, file: UploadFile<UploadExcelDataType> | string) => {
        if (!file) return;
        let data;
        if (typeof file === 'string') {
          if (!isValidJson(file)) {
            return;
          }

          data = JSON.parse(file);
        } else if (file.response?.excelData) {
          data = file.response.excelData;
        }

        return <Excel {...excelProps} data={data} />;
      }}
      accept='.xls,.xlsx'
      customRequest={handleUpload}
    />
  );
};
export default UploadExcel;
