import { Spin, Typography } from 'antd';
import { ServerFileList } from 'components/common';
import dayjs from 'dayjs';
import { internalAnnouncementsMessages } from 'messages';
import { forwardRef, useImperativeHandle } from 'react';
import { InternalAnnouncementDto } from 'types';
import { DATE_FORMAT } from 'utils';

export type InternalAnnouncementInfoProps = {
  data?: InternalAnnouncementDto;
  isLoading?: boolean;
};

export type InternalAnnouncementInfoRefProps = {};

const InternalAnnouncementInfo = forwardRef<InternalAnnouncementInfoRefProps, InternalAnnouncementInfoProps>(
  ({ data, isLoading }, ref) => {
    useImperativeHandle(ref, () => ({}));

    return (
      <div>
        <Spin spinning={isLoading}>
          {data && (
            <>
              <Typography.Title level={5}>{internalAnnouncementsMessages.content}</Typography.Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.content
                }}
                className='mb-2'
              />

              <Typography.Title level={5}>{internalAnnouncementsMessages.files}</Typography.Title>
              <div className='mb-2'>
                <ServerFileList fileList={data.files} />
              </div>

              <Typography.Title level={5}>{internalAnnouncementsMessages.organizationUnit}</Typography.Title>
              <Typography.Text>{data.organizationUnits?.map((org) => org.name).join(', ') || '-'}</Typography.Text>

              <Typography.Title level={5}>{internalAnnouncementsMessages.priority}</Typography.Title>
              <Typography.Text>{data.priority}</Typography.Text>

              <Typography.Title level={5}>{internalAnnouncementsMessages.createdBy}</Typography.Title>
              <Typography.Text>{data.createdBy?.fullName}</Typography.Text>

              <Typography.Title level={5}>{internalAnnouncementsMessages.createdAt}</Typography.Title>
              <Typography.Text>{dayjs(data.createdAt).format(DATE_FORMAT)}</Typography.Text>
            </>
          )}
        </Spin>
      </div>
    );
  }
);
export default InternalAnnouncementInfo;
