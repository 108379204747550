import * as fabric from 'fabric';
import { colorsBase } from 'themes';

export const renderGridMap = (rows: number, cols: number, width: number, canvas?: fabric.Canvas) => {
  if (canvas) {
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const rect = new fabric.Rect({
          left: col * width,
          top: row * width,
          fill: colorsBase.backgroundColor1, // Màu nền của ô, có thể thay đổi
          width: width,
          height: width,
          stroke: colorsBase.colorBorder, // Đường viền của ô
          strokeWidth: 1,
          selectable: false, // Không thể kéo thả
          evented: false // Không thể tương tác
        });
        canvas.add(rect);
      }
    }
  }
};
