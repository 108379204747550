import { Skeleton, Tag } from 'antd';
import { InfoOutlinedIcon } from 'assets';
import { Avatar, MenuItem } from 'components';
import { useCustomerSupportInformationActive } from 'hooks/customer-support-information';
import { customerSupportInformationMessages } from 'messages';
import { useState } from 'react';
import CustomerSupportInformationDetail from './CustomerSupportInformationDetail';

type CustomerSupportInformationListProps = {
  handleCollapsed: () => void;
};

const CustomerSupportInformationList = ({ handleCollapsed }: CustomerSupportInformationListProps) => {
  const { data, isLoading } = useCustomerSupportInformationActive();
  const [open, setOpen] = useState(false);
  const [customerInfoId, setCustomerInfoId] = useState<number>();
  return (
    <>
      {isLoading ? (
        <Skeleton title={false} paragraph={{ rows: 5 }} />
      ) : (
        <>
          {data?.map((itm) => (
            <MenuItem
              onClick={() => {
                handleCollapsed();
                setOpen(true);
                setCustomerInfoId(itm.customerSupportInformationId);
              }}
              item={{
                key: itm.customerSupportInformationId.toString(),
                label: itm.title
              }}
              icon={
                <Avatar
                  size={24}
                  src={<InfoOutlinedIcon />}
                  className='bg-transparent'
                  shape='square'
                  fileId={itm.icon}
                />
              }
              className='px-4'
              suffix={itm.isNew && <Tag>{customerSupportInformationMessages.isNew}</Tag>}
            />
          ))}
          <CustomerSupportInformationDetail
            open={open}
            onCancel={() => {
              setOpen(false);
            }}
            customerSupportInformationId={customerInfoId}
          />
        </>
      )}
    </>
  );
};
export default CustomerSupportInformationList;
