import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { ThreeDots } from 'assets';
import { messages } from 'messages';
import { ReasonDto } from 'types';

type ReasonActionProps = {
  reason: ReasonDto;
  onDelete?: (reason: ReasonDto) => void;
};

const ReasonActions = ({ reason, onDelete }: ReasonActionProps) => {
  const actionItems: ItemType[] = [
    {
      key: 'delete',
      danger: true,
      label: messages.deleteButtonText
    }
  ];

  const onClickActionItem = (action: string, data: ReasonDto) => {
    switch (action) {
      case 'delete':
        onDelete?.(data);
        break;
    }
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        menu={{
          items: actionItems,
          onClick: ({ key }) => {
            onClickActionItem(key, reason);
          }
        }}
      >
        <Button size='small' type='text'>
          <ThreeDots />
        </Button>
      </Dropdown>
    </>
  );
};

export default ReasonActions;
