import { Col, Modal, Row } from 'antd';
import { Loadable } from 'components/common';
import { lazy, useState } from 'react';
import { offReceiveConversation, offReceiveMessage } from 'services';
import { useGetConversationDetailQuery } from 'services';

type InternalChatModalProps = {
  open?: boolean;
  onCancel?: () => void;
};
const InternalChatConversationList = lazy(() => import('./InternalChatConversationList'));
const InternalChatConversation = lazy(() => import('./InternalChatConversation'));
const InternalChatProvider = lazy(() => import('../../contexts/internal-chat/InternalChatProvider'));

const InternalChatModal: React.FC<InternalChatModalProps> = ({ open, onCancel }) => {
  const [selectedConversationId, setSelectedConversationId] = useState<string | undefined>();

  const handleCancelModal = () => {
    onCancel?.();
    offReceiveConversation();
    offReceiveMessage();
  };
  const { data: conversationDetailRes, endpointName } = useGetConversationDetailQuery(selectedConversationId!, {
    skip: !selectedConversationId,
    refetchOnMountOrArgChange: true
  });

  return (
    <Modal
      maskClosable={false}
      footer={null}
      prefixCls='internal-chat-modal'
      width='80vw'
      open={open}
      onCancel={handleCancelModal}
      title
      destroyOnClose
    >
      <Row wrap={false}>
        <Col flex={'400px'} className='flex min-h-[calc(80vh-44px)] border-r border-colorBorderInput'>
          <Loadable>
            <InternalChatConversationList
              onSelectConversation={(data) => setSelectedConversationId(data?.conversationId)}
            />
          </Loadable>
        </Col>
        <Col flex={'auto'} className='min-h-[calc(80vh-44px)]'>
          {open &&
            conversationDetailRes?.data &&
            selectedConversationId === conversationDetailRes?.data.conversationId && (
              <div key={selectedConversationId} className='h-full max-h-full bg-colorBgChat'>
                <Loadable>
                  <InternalChatProvider endpointName={endpointName} conversation={conversationDetailRes?.data}>
                    <Loadable>
                      <InternalChatConversation
                        key={selectedConversationId}
                        conversationId={selectedConversationId}
                        onClose={() => setSelectedConversationId(undefined)}
                      />
                    </Loadable>
                  </InternalChatProvider>
                </Loadable>
              </div>
            )}
        </Col>
      </Row>
    </Modal>
  );
};
export default InternalChatModal;
