import { Form, FormInstance, Input, Skeleton } from 'antd';
import { Rule } from 'antd/es/form';
import { DefaultOptionType } from 'antd/es/select';
import { message } from 'components/common';
import { validateMessages } from 'messages';
import { organizationUnitsMessages } from 'messages/organization-units.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useAddOrganizationUnitMutation,
  useGetOrganizationUnitDetailQuery,
  useUpdateOrganizationUnitMutation
} from 'services/organization-units';
import { CreateOrganizationUnitDto } from 'types';
import {
  AUTH_OPTION_TYPES,
  createOrganizationUnitInitialValues,
  MICROSERVICES,
  ORGANIZATION_UNIT_CLASSIFY,
  organizationUnitValidationRules,
  updateOrganizationUnitInitialValues
} from 'utils';
import { SelectOptions, SelectOrganizationUnits, SelectServiceTypes, SelectUsers } from 'components';
export type OrganizationUnitFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  organizationUnitId?: number;
};

export type OrganizationUnitFormRefProps = {
  form: FormInstance<OrganizationUnitsFormType>;
  isLoading: boolean;
};

export type OrganizationUnitsFormType = Omit<
  CreateOrganizationUnitDto,
  'parentId' | 'serviceTypeIds' | 'organizationUnitClassifyId'
> & {
  parentId?: DefaultOptionType;
  serviceTypeIds: DefaultOptionType[];
  organizationUnitClassifyId?: DefaultOptionType;
};
const OrganizationUnitForm = forwardRef<OrganizationUnitFormRefProps, OrganizationUnitFormProps>(
  ({ onChangeLoading, onCreateSuccess, organizationUnitId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const [form] = Form.useForm<OrganizationUnitsFormType>();
    const classifyId = Form.useWatch('organizationUnitClassifyId', form);

    const { data: organizationUnit, isLoading: isLoadingDetail } = useGetOrganizationUnitDetailQuery(
      organizationUnitId!,
      {
        skip: !organizationUnitId,
        refetchOnMountOrArgChange: true
      }
    );

    const [onCreate, { isLoading: isLoadingCreate }] = useAddOrganizationUnitMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateOrganizationUnitMutation();

    useEffect(() => {
      if (organizationUnit && organizationUnitId) {
        form.setFieldsValue({
          ...organizationUnit.data,
          parentId: organizationUnit.data?.parentId
            ? {
                label: organizationUnit.data?.parent?.name,
                value: organizationUnit.data.parentId
              }
            : undefined,
          serviceTypeIds: organizationUnit.data.serviceTypes
            ? organizationUnit.data.serviceTypes.map((service) => ({
                label: service?.name,
                value: service.serviceTypeId
              }))
            : [],
          deputyUserIds: organizationUnit.data.deputyUsers?.map((user) => user.userId) || [],
          organizationUnitClassifyId: organizationUnit.data.organizationUnitClassifyId
            ? {
                label: organizationUnit.data?.organizationUnitClassify?.name,
                value: organizationUnit.data.organizationUnitClassifyId
              }
            : undefined
        });
      }
    }, [organizationUnit, organizationUnitId]);

    const onFinish = (values: OrganizationUnitsFormType) => {
      const data: CreateOrganizationUnitDto = {
        ...values,
        serviceTypeIds: values?.serviceTypeIds?.map((o) => o.value as number),
        parentId: values.parentId?.value as number,
        organizationUnitClassifyId: values?.organizationUnitClassifyId?.value as number
      };
      if (!organizationUnitId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          organizationUnitId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    const validationRules: Partial<Record<keyof OrganizationUnitsFormType, Rule[]>> = {
      ...organizationUnitValidationRules,
      parentId: [{ required: !organizationUnitId }]
    };
    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='organizationUnitForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={organizationUnitId ? updateOrganizationUnitInitialValues : createOrganizationUnitInitialValues}
      >
        <Skeleton loading={isLoadingDetail}>
          <Form.Item<OrganizationUnitsFormType>
            label={organizationUnitsMessages.upperOrganizationUnit}
            rules={validationRules.parentId}
            name='parentId'
          >
            <SelectOrganizationUnits labelInValue />
          </Form.Item>
          <Form.Item<OrganizationUnitsFormType>
            rules={validationRules.code}
            label={organizationUnitsMessages.code}
            name='code'
          >
            <Input />
          </Form.Item>
          <Form.Item<OrganizationUnitsFormType>
            rules={validationRules.name}
            label={organizationUnitsMessages.organizationUnitName}
            name='name'
          >
            <Input />
          </Form.Item>

          <Form.Item<OrganizationUnitsFormType>
            rules={validationRules.serviceTypeIds}
            label={organizationUnitsMessages.service}
            name='serviceTypeIds'
          >
            <SelectServiceTypes isActive labelInValue mode='multiple' />
          </Form.Item>

          <Form.Item<OrganizationUnitsFormType>
            label={organizationUnitsMessages.classify}
            rules={validationRules.organizationUnitClassifyId}
            name='organizationUnitClassifyId'
          >
            <SelectOptions
              labelInValue
              service={MICROSERVICES.AUTH}
              optionTypeId={AUTH_OPTION_TYPES.ORGANIZATION_UNIT_CLASSIFY}
            />
          </Form.Item>

          {classifyId?.value === ORGANIZATION_UNIT_CLASSIFY.PHONGDOI && (
            <>
              <Form.Item<OrganizationUnitsFormType>
                label={organizationUnitsMessages.headUser}
                rules={validationRules.headUserId}
                name='headUserId'
              >
                <SelectUsers organizationUnitId={organizationUnitId ? [organizationUnitId] : []} />
              </Form.Item>

              <Form.Item<OrganizationUnitsFormType>
                label={organizationUnitsMessages.deputyUser}
                rules={validationRules.deputyUserIds}
                name='deputyUserIds'
              >
                <SelectUsers mode='multiple' organizationUnitId={organizationUnitId ? [organizationUnitId] : []} />
              </Form.Item>
            </>
          )}
        </Skeleton>
      </Form>
    );
  }
);
export default OrganizationUnitForm;
