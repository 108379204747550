import { Button, Empty, List, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Avatar } from 'components/common';
import { SelectUsers } from 'components/users';
import { messages, userGroupsMessages } from 'messages';
import { UserCompactDto } from 'types';

type UpdateUserListProps = {
  users: UserCompactDto[];
  onAdd: (data: UserCompactDto) => void;
  onRemove: (data: UserCompactDto) => void;
  organizationUnitId?: number[];
};
const UpdateUserList = ({ users, onAdd, onRemove, organizationUnitId }: UpdateUserListProps) => {
  return (
    <>
      <SelectUsers
        onSelect={(_value, record) => {
          const item = record as DefaultOptionType & UserCompactDto;
          onAdd(item);
        }}
        placeholder={userGroupsMessages.addUsers}
        size='large'
        className='mb-6 w-full'
        value={null}
        organizationUnitId={organizationUnitId}
      />
      <List
        itemLayout='horizontal'
        dataSource={users}
        rowKey={(item) => item.userId!}
        locale={{
          emptyText: <Empty />
        }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type='link'
                onClick={() => {
                  onRemove(item);
                }}
                className='text-sm'
              >
                {messages.deleteButtonText}
              </Button>
            ]}
          >
            <List.Item.Meta
              className='items-center'
              avatar={<Avatar size={40} name={item.shortName} fileId={item.avatar} />}
              title={<Typography.Text>{item.fullName}</Typography.Text>}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default UpdateUserList;
