import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Input, InputNumber, Spin, Typography, UploadFile } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Editor, FormItem, message, Upload } from 'components/common';
import { ServerUploadFile } from 'components/common/upload/ServerUpload';
import { TreeSelectOrganizationUnits } from 'components/organization-units';
import { useProfile, useUpload } from 'hooks';
import { internalAnnouncementsMessages, messages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateInternalAnnouncementMutation,
  useGetInternalAnnouncementDetailQuery,
  useUpdateInternalAnnouncementMutation
} from 'services';
import { CreateInternalAnnouncementDto } from 'types';
import {
  createInternalAnnouncementInitialValues,
  internalAnnouncementsValidationRules,
  updateInternalAnnouncementInitialValues
} from 'utils';

export type InternalAnnouncementFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  internalAnnouncementId?: number;
  refetchOnMountOrArgChange?: boolean;
};

export type InternalAnnouncementFormRefProps = {
  form: FormInstance<InternalAnnouncementFormType>;
  isLoading: boolean;
};

export type InternalAnnouncementFormType = Omit<CreateInternalAnnouncementDto, 'organizationUnitIds' | 'files'> & {
  organizationUnitIds: DefaultOptionType[];
  files: ServerUploadFile[];
};
const InternalAnnouncementForm = forwardRef<InternalAnnouncementFormRefProps, InternalAnnouncementFormProps>(
  ({ onChangeLoading, onCreateSuccess, internalAnnouncementId, refetchOnMountOrArgChange = true }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const { handleMultiUpload } = useUpload();
    const { profile } = useProfile();
    const { data: internalAnnouncement, isLoading: isLoadingDetail } = useGetInternalAnnouncementDetailQuery(
      internalAnnouncementId!,
      {
        skip: !internalAnnouncementId,
        refetchOnMountOrArgChange
      }
    );

    useEffect(() => {
      if (internalAnnouncement && internalAnnouncementId) {
        form.setFieldsValue({
          ...internalAnnouncement.data,
          organizationUnitIds: internalAnnouncement.data.organizationUnits?.map((org) => ({
            value: org.organizationUnitId,
            label: org.name
          })),
          files: internalAnnouncement.data.files.map((o) => ({
            uid: `file-${o}`,
            fileId: o
          }))
        });
      }
    }, [internalAnnouncement, internalAnnouncementId]);

    const [form] = Form.useForm<InternalAnnouncementFormType>();

    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateInternalAnnouncementMutation();
    const [onCreate, { isLoading: isLoadingCreate }] = useCreateInternalAnnouncementMutation();

    const onFinish = async ({ ...values }: InternalAnnouncementFormType) => {
      const updateFileList = values.files;
      const filesUploaded = await handleMultiUpload(updateFileList, internalAnnouncement?.data.files);
      const data: CreateInternalAnnouncementDto = {
        ...values,
        organizationUnitIds: values.organizationUnitIds.map((o) => o.value as number),
        files: filesUploaded
      };

      if (!internalAnnouncementId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          internalAnnouncementId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate || isLoadingCreate);
      }
    }, [onChangeLoading, isLoadingUpdate, isLoadingCreate]);

    const normFile = ({ fileList }: { fileList: UploadFile[] }) => {
      return fileList;
    };

    const files = Form.useWatch('files', form) || [];
    const content = Form.useWatch('content', form) || '';
    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='internalAnnouncement'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={
          internalAnnouncementId ? updateInternalAnnouncementInitialValues : createInternalAnnouncementInitialValues
        }
      >
        <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
          <FormItem.FloatLabel<InternalAnnouncementFormType>
            name='title'
            rules={internalAnnouncementsValidationRules.title}
            label={internalAnnouncementsMessages.title}
          >
            <Input />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<InternalAnnouncementFormType>
            name='organizationUnitIds'
            rules={internalAnnouncementsValidationRules.organizationUnitIds}
            label={internalAnnouncementsMessages.organizationUnit}
          >
            <TreeSelectOrganizationUnits
              labelInValue
              parentId={profile?.organizationUnitId ? [profile.organizationUnitId] : []}
              showCheckedStrategy='SHOW_ALL'
              treeCheckStrictly
              treeDefaultExpandAll
            />
          </FormItem.FloatLabel>
          <div className='mb-4'>
            <Typography.Title level={5} className='mb-0'>
              {internalAnnouncementsMessages.content}
            </Typography.Title>
            <FormItem<InternalAnnouncementFormType>
              name='content'
              prefixCls='hidden'
              label={internalAnnouncementsMessages.content}
              rules={internalAnnouncementsValidationRules.content}
            ></FormItem>
            <Editor
              onEditorChange={(a) => {
                form.setFieldValue('content', a);
              }}
              value={content}
            />
          </div>
          <Typography.Title level={5} className='mb-4'>
            {internalAnnouncementsMessages.files}
          </Typography.Title>
          <FormItem<InternalAnnouncementFormType>
            valuePropName='fileList'
            name='files'
            getValueFromEvent={normFile}
            rules={internalAnnouncementsValidationRules.files}
          >
            <Upload listType='picture-card'>
              {files.length < 5 ? (
                <button style={{ border: 0, background: 'none' }} type='button'>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{messages.upload}</div>
                </button>
              ) : null}
            </Upload>
          </FormItem>
          <FormItem.FloatLabel<InternalAnnouncementFormType>
            name='priority'
            label={internalAnnouncementsMessages.priority}
            rules={internalAnnouncementsValidationRules.priority}
          >
            <InputNumber min={1} className='w-1/4' />
          </FormItem.FloatLabel>

          <Form.Item<InternalAnnouncementFormType>
            rules={internalAnnouncementsValidationRules.isRequestConfirm}
            name='isRequestConfirm'
            valuePropName='checked'
          >
            <Checkbox>{internalAnnouncementsMessages.isRequestConfirm}</Checkbox>
          </Form.Item>
        </Spin>
      </Form>
    );
  }
);
export default InternalAnnouncementForm;
