import { Modal, Skeleton, Typography } from 'antd';
import Excel from 'components/common/excel/Excel';
import { Link } from 'react-router-dom';
import { useGetCustomerSupportInformationDetailQuery } from 'services';
import { CUSTOMER_SUPPORT_INFORMATION_FORMAT } from 'utils';

type CustomerSupportInformationDetailProps = {
  customerSupportInformationId?: number;
  open: boolean;
  onCancel: () => void;
};

const CustomerSupportInformationDetail = ({
  onCancel,
  open,
  customerSupportInformationId
}: CustomerSupportInformationDetailProps) => {
  const { data: customerSupportInformation, isLoading } = useGetCustomerSupportInformationDetailQuery(
    customerSupportInformationId!,
    {
      skip: !customerSupportInformationId,
      refetchOnMountOrArgChange: true
    }
  );
  function convertStringToMatrix(dataString: string): (string | number)[][] {
    const parsedData: any[][] = JSON.parse(dataString);

    return parsedData.map((row) =>
      row.map((item) => {
        if (item === null || item === undefined) {
          return '';
        }
        return typeof item === 'number' ? item : String(item);
      })
    );
  }
  const renderContent = () => {
    if (customerSupportInformation?.data)
      switch (customerSupportInformation?.data.formatId) {
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.EXCEL_TABLE:
          return <Excel data={convertStringToMatrix(customerSupportInformation.data.content)} />;
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.TEXT:
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: customerSupportInformation.data.content
              }}
            />
          );
        default:
          return (
            <Link to={customerSupportInformation?.data.content} target='_blank'>
              <Typography.Link>{customerSupportInformation?.data.content}</Typography.Link>
            </Link>
          );
      }
  };
  return (
    <Modal
      width={888}
      destroyOnClose
      centered
      onCancel={onCancel}
      open={open}
      title={customerSupportInformation?.data.title}
      footer={null}
    >
      {isLoading ? <Skeleton title={false} paragraph={{ rows: 5 }} /> : <>{renderContent()}</>}
    </Modal>
  );
};
export default CustomerSupportInformationDetail;
