export const ROUTE = {
  ALL: '*',
  CUSTOMER_SUPPORT_INFORMATION: '/customer-support-information',
  FORGOT_PASSWORD: '/forgot-password',
  HOME: '/',
  LOGIN: '/login',
  ORGANIZATION_UNITS: '/organization-units',
  PERMISSIONS: '/permissions',
  PROFILE: '/profile',
  RESET_PASSWORD: '/reset-password',
  ROLES: '/roles',
  SERVICE_TYPES: '/service-types',
  USERS: '/users',
  USER_GROUPS: '/user-groups',
  VERIFY_FORGOT_PASSWORD: '/verify-forgot-password',
  FREQUENTLY_ASKED_QUESTION: '/frequently-asked-questions',
  SERVICE_INSTRUCTIONS: '/service-instructions',
  WORKING_SCHEDULES: '/working-schedules',
  SHIFTS: '/shifts',
  REASONS: '/reasons',
  VIP_CUSTOMERS: '/vip-customers',
  INTERNAL_CHAT: '/internal-chat',
  CUSTOMER_LOOKUP: '/customer-lookup',
  NOTE: '/note',
  TICKET_SAMPLES: '/ticket-samples',
  TICKET_CANCEL_REASONS: '/ticket-cancel-reasons',
  INTERNAL_ANNOUNCEMENTS: '/internal-announcements',
  INTERNAL_ANNOUNCEMENTS_DELETED: '/internal-announcements/deleted',
  RATING_SYSTEM: '/rating-system',
  AGENT_MAPS: '/agent-maps'
};
