import { ConversationParticiPantDto, MessageDto } from 'types';
import {
  MESSAGE_DATE_HEIGHT,
  MESSAGE_FILE_HEIGHT,
  MESSAGE_IMAGE_HEIGHT_LARGE,
  MESSAGE_SPACING,
  MESSAGE_SPACING_LARGE
} from 'utils/constants';
import { MESSAGE_TYPE } from 'utils/enums';
import { calculateHeightMessageImage } from './calculateMessageImage';
import { calculateHeightMessageList } from './calculateMessageImageList';
import { calculateHeightMessageSystem } from './calculateMessageSystem';
import { calculateHeightMessageText } from './calculateMessageText';
import { getPropsMessageItem } from './getPropsMessageItem';

export const calculateHeightMessageItem = (
  message: MessageDto,
  nextMess?: MessageDto | null,
  prevMess?: MessageDto | null,
  isGroup?: boolean,
  profileId?: number,
  sender?: ConversationParticiPantDto
): number => {
  let heightMessage = 0;

  const { isFirstOfSender, isOnSameDate } = getPropsMessageItem(message, nextMess, prevMess);

  switch (message.type) {
    case MESSAGE_TYPE.SYSTEM:
      heightMessage = calculateHeightMessageSystem(message, {
        isGroup,
        isMe: message.senderId === profileId,
        isFirstOfSender,
        sender
      });
      break;
    case MESSAGE_TYPE.TEXT:
      heightMessage = calculateHeightMessageText(message, {
        isGroup,
        isMe: message.senderId === profileId,
        isFirstOfSender
      });
      break;
    case MESSAGE_TYPE.IMAGE:
      if (!message.fileId) {
        heightMessage = MESSAGE_IMAGE_HEIGHT_LARGE;
      }
      if (typeof message.fileId === 'number') {
        heightMessage = calculateHeightMessageImage(message);
      } else if (Array.isArray(message.fileId)) {
        if (message.fileId.length === 1) heightMessage = calculateHeightMessageImage(message);
        else {
          heightMessage = calculateHeightMessageList(message.fileId, message);
        }
      }
      break;
    case MESSAGE_TYPE.FILE:
      heightMessage = MESSAGE_FILE_HEIGHT;
      break;
  }

  /** Chiều cao của ngày gửi tin nhắn khác ngày nhau */
  heightMessage += !isOnSameDate ? MESSAGE_DATE_HEIGHT : 0;

  /** Khoảng cách giữa các tin nhắn của cùng người gửi hay khác người gửi */
  heightMessage += message.senderId !== nextMess?.senderId ? MESSAGE_SPACING_LARGE : MESSAGE_SPACING;

  return heightMessage;
};
