import { Divider, Spin, Tabs, TabsProps, Typography } from 'antd';
import { internalAnnouncementsMessages } from 'messages';
import { forwardRef, useImperativeHandle } from 'react';
import { useGetInternalAnnouncementDeletedDetailQuery } from 'services';
import InternalAnnouncementInfo from './InternalAnnouncementInfo';
import InternalAnnouncementStatistic from './InternalAnnouncementStatistic';

export type InternalAnnouncementDeletedDetailProps = {
  internalAnnouncementId: number;
};

export type InternalAnnouncementDeletedDetailRefProps = {};

const InternalAnnouncementDeletedDetail = forwardRef<
  InternalAnnouncementDeletedDetailRefProps,
  InternalAnnouncementDeletedDetailProps
>(({ internalAnnouncementId }, ref) => {
  useImperativeHandle(ref, () => ({}));

  const { data: internalAnnouncement, isLoading: isLoadingDetail } = useGetInternalAnnouncementDeletedDetailQuery(
    internalAnnouncementId!,
    {
      skip: !internalAnnouncementId,
      refetchOnMountOrArgChange: true
    }
  );
  const data = internalAnnouncement?.data;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: internalAnnouncementsMessages.info,
      children: <InternalAnnouncementInfo data={data} isLoading={isLoadingDetail} />
    },
    {
      key: '2',
      label: internalAnnouncementsMessages.statistic,
      children: <InternalAnnouncementStatistic data={data} isLoading={isLoadingDetail} />
    }
  ];
  return (
    <div className='rounded-lg border border-colorBorderInput bg-colorBgContent p-5'>
      <Spin spinning={isLoadingDetail}>
        {data && (
          <>
            <div>
              <Typography.Title level={3}>{data.title}</Typography.Title>
            </div>
            <Divider />

            <Tabs defaultActiveKey='1' items={items} destroyInactiveTabPane />
          </>
        )}
      </Spin>
    </div>
  );
});
export default InternalAnnouncementDeletedDetail;
